.products-grid{
    margin-top: 120px;
    display: flex;
   justify-content: center;
    flex-wrap: wrap;
}
.product-images{
 width: 160px;
 height: 160px;
 background:rgba(128, 128, 128, 0.696) ;
 margin-bottom: 100px;
 cursor: pointer;
 margin-left: 10px;
 border-radius: 5px;
}
.options-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid   rgba(128, 128, 128, 0.696);
    border-radius: 6px;
  

}
.name-of-product{
    margin-top: 0px;
    color: #39CEAC;
}
.price-of-product{
    margin-top: -16px;
}
.delete-icon{
    cursor: pointer;
    color: rgba(234, 200, 200, 0.755);
    border-left: 1px solid rgba(128, 128, 128, 0.696);
}



.pro-img{
width: 100%;
height: 100%;
}

.button-add{
padding: 6px 32px;
background: #39CEAC;
border: none;
border-radius: 6px;
cursor: pointer;
color: black;
}
