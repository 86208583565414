
    .add {
        margin-top: 20px;
        display: flex;
        gap: 20px;
  
        .content {
          flex: 5;
          display: flex;
          flex-direction: column;
          gap: 20px;
  
          input {
            padding: 10px;
            border: 1px solid rgba(106, 102, 102, 0.681);
            border-radius: 6px;
            background: rgba(128, 128, 128, 0.158);
            color: aliceblue;
          }
  
          .editorContainer {
            height: 300px;
            overflow: scroll;
            border-radius: 6px;
  
            .editor {
              height: 100%;
              border: none;
            }
          }
        }
  
        .menu {
          flex: 2;
          display: flex;
          flex-direction: column;
          gap: 20px;
  
          .item {
            border: 1px solid lightgray;
            padding: 10px;
            flex:1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 12px;
            color: rgb(236, 228, 228);
  
            h1{
              font-size: 20px;
            }
  
            .file{
              text-decoration: underline;
              cursor: pointer;
            }
  
            .buttons{
              display: flex;
              justify-content: space-between;
  
              :first-child{
                cursor: pointer;
                color: teal;
                background-color: white;
                border: 1px solid teal;
                padding: 3px 5px;
              }
              :last-child{
                cursor: pointer;
                color: white;
                background-color: teal;
                border: 1px solid teal;
                padding: 3px 5px;
              }
            }
  
            .cat{
              display: flex;
              align-items: center;
              gap: 2px;
              color: teal;
            }
          }
        }
      }