
    .auth {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: rgba(128, 128, 128, 0);
  
        h1 {
          font-size: 20px;
          color: teal;
          margin-bottom: 20px;
        }
  
        form {
          display: flex;
          flex-direction: column;
          padding: 50px;
          background-color: rgba(233, 227, 227, 0.133);
          border-radius: 6px;
          width: 600px;
          gap: 20px;
  
          input {
            padding: 10px;
            border: none;
            border-bottom: 1px solid gray;
            border-radius: 6px;
          }
  
          button {
            padding: 10px;
            border: none;
            background-color: teal;
            cursor: pointer;
            color: white;
          }
  
          p {
            font-size: 12px;
            color: red;
            text-align: center;
          }
  
          span {
            font-size: 12px;
            text-align: center;
          }
          button{
            width: 100%;
            border-radius: 6px;
          }

        }
      }