
   .form-container{
    display: flex;
    flex-direction:column;

.inpuradiocat{
display: flex;
flex-direction: row;

.radios{
    background: rgba(128, 128, 128, 0.482);
    padding: 3px 35px;
    margin-left: 15px;
    border-radius: 6px;
   
}

}

    input{
    padding: 17px;  
    margin-top: 15px;
    background: transparent;
    color: azure;
    border-radius: 6px;
    border: 1px solid grey;
    }
    select{
      padding: 17px;  
      margin-top: 15px;
      background: grey;
      color: rgb(1, 4, 4);
      border-radius: 6px;
      border: 1px solid grey;
    }
    .productsbtn{
      background:#359078;  
      color: aliceblue;
      padding: 8px;
      border: none;
      border-radius: 4px;
      width: 10%;
      margin-top: 15px;
    }
   }
 
.customizer-main{
  display: flex;
  flex-direction: row;
  .customizer1-container{
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(37, 179, 153);
    border-radius: 6px;
    padding: 20px;
    margin-left: 20px;


    .customizer1-categories{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    }
   }
}
  